import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "components/layout";
import SEO from "components/seo";
const emailEncoder = require('email-encoder');

export default function ContactPage({
    data
  }) {
    return (
        <Layout>
            <SEO
                pageData={{
                    title: data.markdownRemark.frontmatter.title,
                    description: data.markdownRemark.frontmatter.metadescription
                }}
            />

            <div className="bg-green-50 py-16 border-b border-green-100">
                <div className="max-w-screen-md mx-auto px-4 text-3xl md:text-5xl font-black text-center">{data.markdownRemark.frontmatter.title}</div>
            </div>

            <div className="bg-gray-50 py-16">
                <div className="page max-w-screen-xl mx-auto px-4 mb-12 grid md:grid-cols-2 gap-5">
                    <div className="lg:pr-20">
                        <div className="text-3xl font-extrabold mb-6">
                            <span className="inline-block pb-1 border-b-4 border-green-700">Contact us</span>
                        </div>
                        <div className="text-center">
                            {data.markdownRemark.frontmatter.image &&
                                <div className="mb-4">
                                {
                                    (
                                        !data.markdownRemark.frontmatter.image.childImageSharp
                                        && data.markdownRemark.frontmatter.image.extension === 'svg'
                                        &&
                                        <img src={data.markdownRemark.frontmatter.image.publicURL} alt="" className="rounded-full shadow" />
                                    )
                                    ||
                                    <GatsbyImage
                                        image={data.markdownRemark.frontmatter.image.childImageSharp.gatsbyImageData}
                                        alt=""
                                        className="mx-auto rounded-full shadow" />
                                }
                                </div>
                            }
                            <div>{data.markdownRemark.frontmatter.phone}</div>
                            <p dangerouslySetInnerHTML={{ __html: emailEncoder(`<a href="mailto:` + data.markdownRemark.frontmatter.email + `" class="underline">` + data.markdownRemark.frontmatter.email + `</a>`) }}></p>
                            <p className="text-xl">Are you ready to get your finances back on track? Contact us now for a no-obligation chat about how we can help you.</p>
                        </div>
                        {/* <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.frontmatter.address}} /> */}
                    </div>
                    <div className="">
                        <form method="post" action="#">
                            <input type="hidden" name="form" value="Contact form" />
                            <label>Name <input type="text" name="required-name" id="cName" maxlength="50" className="text-gray-600 w-full mb-4 border-gray-300" required /></label>
                            <label>Phone <input type="text" name="required-phone" maxlength="50" className="text-gray-600 w-full mb-4 border-gray-300" required /></label>
                            <label>Email <input type="text" name="required-email" maxlength="100" className="text-gray-600 w-full mb-4 border-gray-300" required /></label>
                            <label>Message <textarea name="required-message" cols="40" rows="6" className="text-gray-600 w-full mb-2 border-gray-300" required></textarea></label>
                            <button type="submit" className="inline-block rounded py-2 px-8 bg-green-700 text-white font-semibold">Send Message</button>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
export const pageQuery = graphql`query ContactPageQuery {
  markdownRemark(frontmatter: {section: {eq: "contact"}}) {
    frontmatter {
      title
      metadescription
      address
      phone
      email
      image {
        extension
        publicURL
        childImageSharp {
          gatsbyImageData(width: 200, layout: FIXED)
        }
      }
    }
    html
  }
}
`